@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter;
}

.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.nav-content {
  padding: 0 5%;
}

.nav-content .button-div {
  border: 1px solid #9CA3AF;
  padding: 5px 10px;
}

.nav-content .drop-down-area {
  border: 1px solid #9CA3AF;
}

p {
  margin: 0;
}

.css-t3ipsp-control {
  border-width: 0 !important;
  box-shadow: none !important;
  outline: none !important;
}

.css-19bb58m input {
  width: 0% !important;
}

.hides-scrollbar {
  -ms-overflow-style: auto;
  scrollbar-width: auto;
}

.option-tag {
  padding: 12px;
  margin: 12px;
}

.stay-search-select .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: white;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline:hover {
  border-color: white;
  outline: none;
  box-shadow: none;
}

.back-img {
  background-image: url("../public/stay_home_img/stay_top_back.svg");
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
}

.main-heading {
  color: #FFFFFF;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 1%;
}

.stay-top-button-grp {
  display: flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.stay-top-button-grp .selecteds {
  border-bottom: 2px solid #002E8A;
}

.stay-top-buttons-div p {
  margin: 0;
}

.stay-top-button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
}

.stay-search-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 12px;
  background-color: #002E8A;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
}

.search-stay-div {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 4% 2%;
}

.stay-top-input-div {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 5px;
  border-radius: 5px;
  gap: 10px;
}

.btn-grp {
  align-items: center;
  display: flex;
}

.input-tag {
  border: none;
  width: 100%;
}

.input-tag:focus {
  outline: none;
  box-shadow: none;
}

.stay-top-input-div .calendar-area {
  display: flex;
  align-items: center;
  gap: 5px;
}

.css-13cymwt-control {
  border: none !important;
  border-color: white !important;
  outline: none !important;
  box-shadow: none !important;
}

.css-13cymwt-control:hover {
  border-color: #FFFFFF !important;
}

.react-datepicker__input-container input {
  border: none;
  box-shadow: none;
}

.react-datepicker__input-container input:focus {
  outline: none;
  box-shadow: none;
}

.stay-top-input-div .drop-down-area {
  width: 100%;
}

.css-mpdt1w {
  padding: 0px 10px 10px 10px !important;
  border-radius: 5px;
}

.drop-down-area .drop-down {
  border: none;
  box-shadow: none;
}

.css-b62m3t-container {
  width: 100%;
}

/* Stya middle area */

.div-heading .heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: #0C2252;
}

.div-heading .sub-heading {
  font-size: 16px;
  color: #374151;
  font-weight: 400;
}

.comment-card {
  padding: 7%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.comment-card .comma-img {
  width: 34px;
  height: 30px;
}

.comment-card-heading {
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}

.comment-card-msg {
  font-size: 14px;
  font-weight: 300;
  color: #000000;
}

.comment-card-footer {
  font-size: 14px;
  font-weight: 700;
  color: #000000;
}

.comment-card-time {
  font-size: 12px;
  font-weight: 300;
  color: #000000;
}

.comment-card .star-div {
  display: flex;
  align-items: center;
  gap: 15px;
}

.comment-card .star-div .star {
  background-color: #0E9F6E;
  display: flex;
  align-items: center;
  padding: 2px;
}

.star-main {
  display: flex;
  align-items: center;
  gap: 2px;
}

.comment-card .star-div img {
  width: 14px;
  height: 13.25px;
}

/*Swiper Slide */
.swiper {
  width: 100%;
  height: 100%;
}

.slideComment {
  width: 70%;
  margin: 0.5%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination {
  position: relative !important;
  bottom: -8% !important;
}

/* Destination */

.destination-btn {
  background-color: #C3DDFD;
  padding: 4px 16px;
  border-radius: 100px;
  color: #000F2E;
  /* font-weight: 500; */
}

.country-img-div {
  background: linear-gradient(180deg, rgba(30, 46, 94, 0) 46.88%, rgba(30, 46, 94, 0.8) 100%);
}

.country-img-name .place {
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  color: #FFFFFF;
}

.country-img-name .captal {
  font-size: 12px;
  font-weight: 400;
  font-family: Roboto;
  color: #FFFFFF;
}

.country-img-detail .start-from {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  font-family: Roboto;
  color: #FFFFFF;
}

.country-img-detail .amount {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  color: #FFFFFF;
}

.country-img-detail .longitude {
  font-size: 10px;
  font-weight: 300;
  line-height: 14px;
  color: #FFFFFF;
}

.selected {
  background-color: #002E8A;
  color: #FFFFFF;
  font-weight: 600;
}

.stay-anywhere-img .place-name-div {
  background: linear-gradient(180.27deg, rgba(255, 255, 255, 0) 50%, rgba(30, 46, 94, 0.6) 99.76%);
}

.stay-anywhere-img .place-name {
  font-size: 24px;
  font-weight: 700;
  color: #FFFFFF;
}

/* Footer */
.footer-div {
  box-shadow: 0px -12px 8px 0px #00000005;
  box-shadow: 0px -2px 2px 0px #1C054D0D;
}

.border-spl {
  border: 1px solid rgb(221, 195, 195);
}

.btn-grp {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  padding-top: 0
}

table {
  border-collapse: collapse;
  width:100%;
}
thead th{
  font-weight: 500;
}

th,
td{
  padding: 8px 15px;
}

.room-tbody > tr:nth-of-type(even) {
  background-color: #E5E7EB;
}

.excellent-progress{
  background: linear-gradient(90deg, #002E8A 80.72%, #EBF3FF 80.73%, #EBF3FF 80.74%, #FFFFFF 100%);
}
.css-ttgsjq-MuiSlider-track{
  background-color: #002E8A !important;
}
.css-cp2j25-MuiSlider-thumb{
  background-color: #C3DDFD !important;
}
.PhoneInputInput:focus{
  outline:none;
  padding-left: 2px;
}
.MuiAutocomplete-endAdornment{
  right: 0px !important;
}

/* For the date picker */
.disabled-day {
  pointer-events: none;
  color: #ccc; 
  cursor: not-allowed;
}

/*For react-datepicker */
.react-datepicker{
  font-size: 0.9rem !important;
  border: none !important;
  width: 588px;
}
.react-datepicker .react-datepicker__month-container .react-datepicker__month{
  margin: 0.6rem !important;
}
.react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week{
  padding: 3px !important;
}
.react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day{
  width: 2rem !important;
  margin: 0.20rem !important;
}
.react-datepicker__day-name{
  width: 2.1rem !important;
}
.react-datepicker-popper{
  z-index: 99 !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.react-datepicker-wrapper{
  width:100%;
}

/*For textHelper */
.css-k4qjio-MuiFormHelperText-root {
  margin-top: 4px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
}
.css-1wc848c-MuiFormHelperText-root {
  margin-top: 4px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
}

@media (max-width: 500px) {
  .hides-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@media (max-width: 1535px) {
  .react-datepicker{
    width: 521px;
  }
  .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day{
    width: 1.9rem !important;
    margin: 0.10rem !important;
  }
  .react-datepicker__day-name{
    width: 1.8rem !important;
  }
}

@media (max-width: 590px) {
  .react-datepicker__month-container {
    float: none !important;
  }
  .react-datepicker{
    width: auto;
  }
  .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day{
    width: 2.3rem !important;
    margin: 0.10rem !important;
  }
  .react-datepicker__day-name{
    width: 2.2rem !important;
  }
  .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week{
    padding: 1px !important;
  }
}
